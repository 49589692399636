<template>
    <div class="container min-height">
        <top active="2"></top>
        <img class="header-image" src="../../assets/image/1546.png" width="3840" alt="" style="width: 100%" />
        <div class="main">
            <div class="width-100">
                <div class="width-100 margin-b flex-row justify-content-center align-items-center" style="margin: 20px 0 40px">
                    <img src="../../assets/image/1548.png" height="24" alt="" />
                    <p v-if="ifMyApplicationForm" class="fs-big black margin-l fw-mid margin-r">报名表签名</p>
                    <p v-else class="fs-big black margin-l fw-mid margin-r">签章</p>
                    <img src="../../assets/image/1548.png" height="24" alt="" />
                </div>
                <div class="padding-Tb-20"></div>
                <!--        <el-empty :image="require('../assets/image/1176.png')" :image-size="200" description="提交成功 请进行签章"></el-empty>-->
                <div class="width-100 margin-b flex-column justify-content-start align-items-center">
                    <div v-for="(item, index) in list" :key="index" class="width-50 border-b margin-b flex-row justify-content-spaceBetween align-items-center">
                        <!-- <div class="width-50 border-b margin-b flex-row justify-content-spaceBetween align-items-center"> -->
                        <p class="margin-bs">
                            <span class="fs-mid black fw-mid margin-r">{{ item.type === '0' ? '著作权人' : item.type === '-1' ? '申报人' : '作者' }}：{{ item.info.ownerName }}</span
                            >{{ item.info.cardNo }}
                        </p>
                        <el-button v-if="item.signatureStatus == 2" disabled class="width-20" size="small" type="success" @click="signature()">已签章</el-button>
                        <el-button v-if="item.signatureStatus == 1 && !ifMyApplicationForm" class="width-20" size="small" type="primary" @click="show = true">去签章</el-button>
                        <el-button v-if="item.signatureStatus == 1 && ifMyApplicationForm" class="width-20" size="small" type="primary" @click="show = true">去签名</el-button>
                        <el-button v-if="item.signatureStatus == 0" disabled class="width-20" size="small" type="info" @click="signature()">等待签章</el-button>
                        <!-- </div> -->
                    </div>
                </div>
                <div class="width-100 margin-b flex-row justify-content-center align-items-center" style="margin: 20px 0 40px">
                    <img v-if="payFlag" src="../../assets/image/paySuccess.png" height="60" alt="" @click="openPayUrl" />
                    <img v-else src="../../assets/image/payFalse.png" height="70" alt="" />
                </div>
                <el-dialog :visible.sync="show" title="" width="20%" center>
                    <div class="width-100 flex-column justify-content-start align-items-center">
                        <div class="bg-grey ewm">
                            <vue-qr :text="url" class="ewm"></vue-qr>
                        </div>
                        <p v-if="!myApplicationFormflag" class="fs-sml black margin-ts width-100 textAlign-c" style="color: red">签章位置：{{ type === '0' ? '著作权人' : '作者' }}</p>
                        <p class="fs-sml black margin-ts width-100 textAlign-c">签章人员：{{ name }}</p>
                        <p class="fs-sml black margin-ts width-100 textAlign-c">待签章人身份证：{{ idcard }}</p>
                        <p class="fs-sml black margin-ts width-100 textAlign-c">手机端签章：请使用浙里办扫描去签章</p>
                        <p class="fs-sml black margin-ts width-100 textAlign-c">网页端签章：{{ url }}</p>
                    </div>
                    <span slot="footer" class="dialog-footer">
                        <el-button type="primary" @click="show = false">我知道了</el-button>
                    </span>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
import vueQr from 'vue-qr';
import top from '../../components/top.vue';
import { getSignatureList, getCopyrightInfo, getApplicationFormSignatureUrl, getCopyrightPayUrl } from '@/api/copyright';

export default {
    name: 'Step6',
    components: {
        vueQr,
        top
    },
    data() {
        return {
            copyrightId: Number(this.$route.query.copyrightId),
            list: [],
            form: {
                name: '', // 姓名或单位名称
                mobile: '', // 联系方式
                address: '', // 详细地址
                contact: '', // 联系人
                contactMobile: '', // 联系人联系方式
                email: '' // 邮箱
            },
            show: false,
            name: '',
            idcard: '',
            url: '',
            type: '1',
            updateTime: 0,
            timer: null,
            myApplicationForm: null,
            myApplicationFormflag: false,
            ifMyApplicationForm: false,
            copyrightPayUrl: null,
            payFlag: false
        };
    },
    beforeDestroy() {
        // console.log('beforeDestroy');
        clearInterval(this.timer);
    },
    async created() {
        await this.setCopyrightInfo();
        if (this.myApplicationForm) {
            await this.ApplicationFormSignature();
            console.log(1);
            this.timer = setInterval(async () => {
                console.log(2);
                await this.ApplicationFormSignature();
            }, 3000);
        } else {
            await this.startSignature();
        }
    },
    methods: {
        async signature() {
            // updateTime
            let signatureStatus = 0;
            const signatureList = (await getSignatureList(this.copyrightId)).data;
            if (signatureList && signatureList.length > 0 && signatureList[0].updateTime === this.updateTime) return;
            this.updateTime = signatureList[0].updateTime;
            this.show = false;
            this.list = signatureList.map((row) => {
                if (row.signatureFlag) {
                    return {
                        ...row,
                        signatureStatus: 2
                    };
                } else {
                    if (signatureStatus === 0) {
                        signatureStatus = 1;
                        this.name = row.info.ownerName;
                        this.idcard = row.info.cardNo;
                        this.url = row.url;
                        this.type = row.type;
                        return {
                            ...row,
                            signatureStatus
                        };
                    } else {
                        return {
                            ...row,
                            signatureStatus: 0
                        };
                    }
                }
            });
            // 支付
            if (this.list.filter((row) => row.signatureStatus === 2).length === this.list.length) {
                console.log(6);
                clearInterval(this.timer);
                console.log(this.timer);
                this.payFlag = true;
                // if (this.copyright.copyrightInfo.status === 11) {
                //     await this.setCopyrightInfoPayInfo();
                // }
            }
        },
        onSubmit(e) {
            this.$emit('changeStep', e);
        },
        async setCopyrightInfo() {
            const copyright = (await getCopyrightInfo(this.copyrightId)).data;
            this.copyright = copyright;
            if (copyright.copyrightInfo.copyrightActivityType) {
                this.myApplicationForm = copyright.myApplicationForm;
                this.myApplicationFormflag = true;
            }
        },
        async openPayUrl() {
            if (!this.copyrightPayUrl) {
                const copyrightPay = (await getCopyrightPayUrl(this.copyrightId)).data;
                if (copyrightPay.payStatus === '1') {
                    this.copyrightPayUrl = copyrightPay.payUrl;
                    window.open(this.copyrightPayUrl);
                } else {
                    this.$message({
                        message: copyrightPay.payMag,
                        type: 'success'
                    });
                }
            }
        },
        async ApplicationFormSignature() {
            const applicationFormSignatureUrl = (await getApplicationFormSignatureUrl(this.copyrightId)).data;
            console.log('applicationFormSignatureUrl:', applicationFormSignatureUrl);
            if (applicationFormSignatureUrl.url && applicationFormSignatureUrl.info) {
                this.ifMyApplicationForm = true;
                this.list = [
                    {
                        info: {
                            ownerName: applicationFormSignatureUrl.info.signName,
                            cardNo: applicationFormSignatureUrl.info.idcard
                        },
                        type: '-1',
                        signatureStatus: 1
                    }
                ];
                this.url = applicationFormSignatureUrl.url;
                this.name = applicationFormSignatureUrl.info.signName;
                this.idcard = applicationFormSignatureUrl.info.idcard;
            } else {
                this.ifMyApplicationForm = false;
                console.log(3);
                if (this.timer) {
                    console.log(5);
                    clearInterval(this.timer);
                    this.timer = null;
                }
                this.startSignature();
            }
        },
        async startSignature() {
            console.log(4);
            await this.signature();
            this.timer = setInterval(async () => {
                await this.signature();
            }, 3000);
            console.log(this.timer);
        }
    }
};
</script>

<style lang="less" scoped>
.ewm {
    width: 165px;
    height: 165px;
}
</style>
